import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import urlJoin from "url-join";

import { SITE_DOMAIN, SITE_URL } from "@Settings";
import { Seo as TypeSeo } from "@Types/api";

type SeoProps = {
    pageSeo?: TypeSeo | null;
};

const Seo: React.FC<SeoProps> = ({ pageSeo }) => {
    const router = useRouter();

    const title = pageSeo?.title;
    const description = pageSeo?.description;
    const keywords = pageSeo?.keywords;

    const getCanonicalURL = () => {
        if (pageSeo?.canonicalURL) {
            return pageSeo.canonicalURL;
        }

        if (router.pathname === "/") {
            return SITE_URL;
        }

        return urlJoin(SITE_URL, router.asPath.split("?")[0]);
    };

    return (
        <Head>
            <meta property="og:locale" content="ru_RU" />
            <meta property="og:site_name" content={SITE_DOMAIN} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={getCanonicalURL()} />
            <meta
                property="og:image"
                content={urlJoin(SITE_URL, "images/stylish2010.jpg")}
            />
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}

            {title && <meta property="og:title" content={title} />}

            {description && (
                <meta property="og:description" content={description} />
            )}

            {title && <meta name="twitter:title" content={title} />}

            {description && (
                <meta name="twitter:description" content={description} />
            )}

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content={SITE_DOMAIN} />
            <meta property="twitter:url" content={getCanonicalURL()} />
            <meta
                name="twitter:image"
                content={urlJoin(SITE_URL, "images/stylish2010.jpg")}
            />

            {/* {robots && <meta name="robots" content={robots} />} */}
            {title && <title>{title}</title>}
            <link rel="canonical" href={getCanonicalURL()} />
        </Head>
    );
};

export default Seo;
