import cns from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { FreeMode, Keyboard, Mousewheel, Navigation } from "swiper/modules";
import { SwiperProps } from "swiper/react";
import { Swiper } from "swiper/types";

import Slider from "@Components/Slider";
import Link from "@Components/ui/Link";
import SvgIcon from "@Components/ui/SvgIcon";
import TextIcon from "@Components/ui/TextIcon";

import styles from "./index.module.scss";

export type SectionSliderProps = {
    className?: string;
    classNameTitle?: string;
    classNameSlider?: string;
    classNameSlide?: string;
    title?: string;
    link?: {
        link: string;
        text?: string;
    };
    headerContent?: ReactNode;
    slides: ReactNode[];
    sliderProps?: SwiperProps;
};

const SectionSlider: React.FC<SectionSliderProps> = ({
    className,
    classNameTitle,
    classNameSlider,
    classNameSlide,
    title,
    link,
    headerContent,
    slides,
    sliderProps,
}) => {
    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const refArrowPrev = useRef<HTMLButtonElement>(null);
    const refArrowNext = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [swiper]);

    if (!slides || !slides.length) {
        return null;
    }

    return (
        <section className={cns(className, styles.component)}>
            <header className={styles.component__header}>
                {title && (
                    <h2
                        className={cns(
                            classNameTitle,
                            styles.component__title,
                            "text text_h3 text_uppercase"
                        )}
                    >
                        {title}
                    </h2>
                )}

                {link && (
                    <Link className={styles.component__link} href={link.link}>
                        <TextIcon
                            textClassName={"link"}
                            iconClassName={styles.component__linkIcon}
                            icon="angle-right"
                            isRight
                        >
                            {link.text || "Смотреть все"}
                        </TextIcon>
                    </Link>
                )}

                {headerContent && (
                    <div className={styles.component__headerContent}>
                        {headerContent}
                    </div>
                )}

                {slides.length > 1 && (
                    <div
                        className={cns(
                            styles.component__arrows,
                            "notForMobile"
                        )}
                    >
                        <button
                            ref={refArrowPrev}
                            className={cns(
                                styles.component__arrow,
                                styles.component__arrow_prev
                            )}
                            type="button"
                            aria-label="Предыдущий слайд"
                        >
                            <SvgIcon icon="angle-left" />
                        </button>

                        <button
                            ref={refArrowNext}
                            className={cns(
                                styles.component__arrow,
                                styles.component__arrow_next
                            )}
                            type="button"
                            aria-label="Следующий слайд"
                        >
                            <SvgIcon icon="angle-right" />
                        </button>
                    </div>
                )}
            </header>

            <Slider
                className={cns(classNameSlider, styles.component__slider)}
                classNamesSlide={cns(classNameSlide, styles.component__slide)}
                modules={[FreeMode, Keyboard, Mousewheel, Navigation]}
                slidesPerView="auto"
                cssMode
                freeMode
                breakpoints={{
                    1025: {
                        cssMode: false,
                    },
                }}
                navigation={{
                    prevEl: refArrowPrev.current,
                    nextEl: refArrowNext.current,
                }}
                {...sliderProps}
                slides={slides.map((slide, slideIndex) => (
                    <React.Fragment key={slideIndex}>{slide}</React.Fragment>
                ))}
                onSwiper={setSwiper}
            />
        </section>
    );
};

export default SectionSlider;
