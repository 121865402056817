import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useRouter } from "next/router";

import {
    ContentPageItem,
    ProductFull,
    ProductRecommendationsResponse,
    ProductReviews,
} from "@Types/api";

import { useApiClient, useDataContext } from "./client";
import { useCommonDataOfflineShops } from "./common";

export const useGetPageData = <T = unknown>() => {
    const value = useDataContext();

    return value.data.page as T;
};

export const useGetPageDataCategories = () => {
    const value = useDataContext();

    return value.data.page.categories;
};

export const useGetPageDataCategoriesNested = () => {
    const value = useDataContext();

    return value.data.page.categoriesNested;
};

export const useGetPageDataCategory = () => {
    const value = useDataContext();

    return value.data.page.category;
};

export const useGetPageDataProducts = () => {
    const value = useDataContext();

    return value.data.page.products;
};

export const useGetPageDataProductsPagination = () => {
    const value = useDataContext();

    return value.data.page.productsPagination;
};

type ProductPageData = {
    product: ProductFull;
    productRecommendations: ProductRecommendationsResponse;
    productReviews: ProductReviews;
};

export const useGetPageDataProduct = () => {
    // TODO: check bug with color after change

    // const apiClient = useApiClient();
    // const value = useDataContext<ProductPageData>();
    // const router = useRouter();
    // const slug = String(router.query.slug);
    // const color = _.isString(router.query.color)
    //     ? router.query.color
    //     : undefined;

    // const { data } = useQuery({
    //     queryKey: ["product", slug, color],
    //     queryFn: () =>
    //         apiClient.catalog.getProduct(
    //             slug,
    //             Boolean(color)
    //                 ? {
    //                       TSVET: color,
    //                   }
    //                 : undefined
    //         ),
    //     enabled: Boolean(slug),
    //     placeholderData: value.data.page.product,
    // });

    // return data as ProductFull;

    const value = useDataContext();

    return value.data.page.product;
};

export const useGetPageDataProductRecommendations = () => {
    const value = useDataContext();

    return value.data.page.productRecommendations;
};

export const useGetPageDataProductReviews = () => {
    const value = useDataContext<ProductPageData>();

    return value.data.page.productReviews;
};

export const useGetPageDataFilters = () => {
    const value = useDataContext();

    return value.data.page.filters;
};

export const useGetPageDataBannersTop = () => {
    const value = useDataContext();

    return value.data.page.bannersTop;
};

export const useGetPageDataBannersMiddle = () => {
    const value = useDataContext();

    return value.data.page.bannersMiddle;
};

export const useGetPageDataBannersBottom = () => {
    const value = useDataContext();

    return value.data.page.bannersBottom;
};

export const useGetPageDataPage = <T = ContentPageItem | undefined>() => {
    const value = useDataContext();

    return value.data.page.page as T;
};

export const usePageDataContacts = () => {
    const offlineShops = useCommonDataOfflineShops();

    return {
        offlineShops,
        offlineShopsByCity: _.groupBy(offlineShops, s => s.city),
    };
};

export const usePageDataMain = () => {
    return useGetPageDataPage<ContentPageItem>();
};

type AboutUsPageData = {
    page: ContentPageItem;
};

export const usePageDataAboutUs = () => {
    const { data } = useDataContext<AboutUsPageData>();
    return data.page.page;
};

type ProductionPageData = {
    page: ContentPageItem;
};

export const usePageDataProduction = () => {
    const { data } = useDataContext<ProductionPageData>();
    return data.page.page;
};

export const useGetPageDataBrandVideos = () => {
    const value = useDataContext();

    return value.data.page.brandVideos;
};

export const useGetPageDataTeam = () => {
    const value = useDataContext();

    return value.data.page.team;
};

export const useGetPageDataTrust = () => {
    const value = useDataContext();

    return value.data.page.trust;
};

export const useGetPageDataFaq = () => {
    const value = useDataContext();

    return value.data.page.faq;
};

export const useGetPageDataHighlights = () => {
    const value = useDataContext();

    return value.data.page.highlights;
};

export const useGetPageDataLookbooks = () => {
    const value = useDataContext();

    return value.data.page.lookbooks;
};

export const useGetPageDataLookbook = () => {
    const value = useDataContext();

    return value.data.page.lookbook;
};
