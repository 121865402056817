import cns from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Swiper from "swiper";
import { Pagination } from "swiper/modules";

import { popupId } from "@Components/PopupProduct";
import Slider from "@Components/Slider";
import Button from "@Components/ui/Button";
import Image, { ImageProps } from "@Components/ui/Image";
import Link from "@Components/ui/Link";
import SvgIcon from "@Components/ui/SvgIcon";
import Video from "@Components/ui/Video";
import useIsTouchDevice from "@Hooks/detect-touch";
import { useFavouriteProductMethods } from "@Hooks/user";
import useWindowSize from "@Hooks/window-size";
import { open } from "@Redux/popup";
import { Offer } from "@Types/api";
import { formatCurrency, isVideoSource } from "@Utils/helpers";

import styles from "./index.module.scss";

export type CardProductProps = {
    className?: string;
    id: string | number;
    slug: string;
    link: string;
    name: string;
    price: number;
    priceOld?: number | null;
    labels?: string[];
    labelsSpecial?: number[];
    mobileSlider?: boolean;
    gallery: string[];
    isAvailable: boolean;
    offer?: Offer;
    imageSizes?: {
        default?: ImageProps["size"];
        tablet?: ImageProps["sizeTablet"];
        mobile?: ImageProps["sizeMobile"];
    };
};

type GalleryItemProps = {
    className?: string;
    src: string;
    autoPlay?: boolean;
    autoPlayInView?: boolean;
    imageSizes?: CardProductProps["imageSizes"];
};

const GalleryItem: React.FC<GalleryItemProps> = ({
    className,
    src,
    autoPlay,
    autoPlayInView,
    imageSizes,
}) => {
    if (isVideoSource(src)) {
        return (
            <Video
                className={className}
                src={src}
                autoPlay={autoPlay}
                autoPlayInView={autoPlayInView}
                loop
                muted
                playsInline
                preload="none"
            />
        );
    }

    return (
        <Image
            className={className}
            src={src}
            alt=""
            fill
            size={imageSizes?.default}
            sizeTablet={imageSizes?.tablet}
            sizeMobile={imageSizes?.mobile}
        />
    );
};

const OutOfStockBadge: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <div
            className={cns(
                className,
                styles.outOfStock,
                "text text_jost text_p4"
            )}
        >
            Нет в наличии
        </div>
    );
};

const CardProduct: React.FC<CardProductProps> = ({
    id,
    slug,
    className,
    link,
    name,
    price,
    priceOld,
    labels,
    labelsSpecial,
    mobileSlider = true,
    gallery,
    isAvailable,
    offer,
    imageSizes = {
        default: "25vw",
        tablet: "33vw",
        mobile: "70vw",
    },
}) => {
    const { isFavourite, toggleFavourites } = useFavouriteProductMethods(id);

    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const refPagination = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const isTouchDevice = useIsTouchDevice();

    const sortedGallery = useMemo(() => {
        if (isVideoSource(gallery[0])) {
            return [gallery[0]];
        }

        const itemsImages: string[] = [];
        const itemsVideos: string[] = [];

        gallery.forEach(item => {
            if (isVideoSource(item)) {
                itemsVideos.push(item);
            } else {
                itemsImages.push(item);
            }
        });

        return [...itemsImages, ...itemsVideos];
    }, [gallery]);

    const handleMouseenterHoverArea = (id: number) => {
        if (swiper && !swiper.destroyed) {
            swiper.slideTo(id, 0);
        }
    };

    const handleFavoritesClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        void toggleFavourites();
    };

    const handleViewClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(
            open({
                id: popupId,
                extra: { slug, offerId: offer?.ID.toString() },
            })
        );
    };

    const handleSlideChange = () => {
        swiper?.slides?.forEach((slide, slideIndex) => {
            const $video = slide?.querySelector("video");

            if ($video && $video.paused && slideIndex === swiper?.activeIndex) {
                $video.currentTime = 0;

                $video.play();
            } else if ($video && !$video.paused) {
                $video.pause();
            }
        });
    };

    useEffect(() => {
        if (swiper && !swiper.destroyed) {
            swiper.updateSlidesClasses();
        }
    }, [windowSize.width]);

    return (
        <Link
            className={cns(className, styles.component)}
            href={link}
            aria-label={name}
        >
            <div className={styles.component__body}>
                <div
                    className={cns(styles.component__gallery, {
                        [styles.component__gallery_noShadow]:
                            sortedGallery.length <= 1 || !mobileSlider,
                    })}
                >
                    {!isAvailable && (
                        <OutOfStockBadge
                            className={styles.component__outOfStock}
                        />
                    )}

                    {(windowSize.isTablet && !mobileSlider) ||
                    sortedGallery.length <= 1 ? (
                        <div className={styles.component__galleryItems}>
                            <div className={styles.component__galleryItem}>
                                <GalleryItem
                                    src={sortedGallery[0]}
                                    autoPlayInView
                                    imageSizes={imageSizes}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <Slider
                                className={styles.component__galleryItems}
                                classNamesSlide={styles.component__galleryItem}
                                modules={[Pagination]}
                                loop
                                pagination={{
                                    el: refPagination.current,
                                }}
                                slides={sortedGallery.map((item, itemIndex) => (
                                    <GalleryItem
                                        key={itemIndex}
                                        src={item}
                                        imageSizes={imageSizes}
                                    />
                                ))}
                                onSwiper={setSwiper}
                                onInit={handleSlideChange}
                                onSlideChange={handleSlideChange}
                            />

                            <div ref={refPagination}></div>
                        </>
                    )}

                    {windowSize.isDesktop &&
                        sortedGallery.length > 1 &&
                        (!isTouchDevice || !mobileSlider) && (
                            <div className={styles.component__hoverAreas}>
                                {sortedGallery.map((item, itemIndex) => (
                                    <div
                                        key={itemIndex}
                                        className={styles.component__hoverArea}
                                        onMouseEnter={() =>
                                            handleMouseenterHoverArea(itemIndex)
                                        }
                                    ></div>
                                ))}
                            </div>
                        )}
                </div>

                {labels && !!labels.length && (
                    <div
                        className={cns(
                            styles.component__labels,
                            "text text_p5 text_center text_uppercase"
                        )}
                    >
                        {labels.map((label, labelIndex) => (
                            <div
                                key={labelIndex}
                                className={cns(styles.component__label, {
                                    [styles.component__label_special]:
                                        labelsSpecial &&
                                        labelsSpecial.includes(labelIndex),
                                })}
                            >
                                {label}
                            </div>
                        ))}
                    </div>
                )}
                <button
                    className={cns(styles.component__favorites, {
                        isActive: isFavourite,
                    })}
                    type="button"
                    aria-label={
                        isFavourite
                            ? "Убрать товар из избранного"
                            : "Добавить товар в избранное"
                    }
                    onClick={handleFavoritesClick}
                >
                    <SvgIcon icon="heart" />
                </button>
                <div className={cns(styles.component__view, "forDesktop")}>
                    <Button size="sm" color="light" onClick={handleViewClick}>
                        Быстрый просмотр
                    </Button>
                </div>
            </div>

            <div className={styles.component__info}>
                <div className={cns(styles.component__name, "text text_p3")}>
                    {name}
                </div>

                <div
                    className={cns(
                        styles.component__price,
                        "text text_p2 text_medium text_uppercase"
                    )}
                >
                    {priceOld && (
                        <span className="text text_regular">
                            {formatCurrency(priceOld)}
                        </span>
                    )}
                    {formatCurrency(price)}
                </div>
            </div>
        </Link>
    );
};

export default CardProduct;
