import cns from "classnames";
import _ from "lodash";

import CardProduct from "@Components/CardProduct";
import SectionSlider, { SectionSliderProps } from "@Components/SectionSlider";
import { ProductItemRec } from "@Types/api";

import styles from "./index.module.scss";

type SectionProductsProps = Omit<SectionSliderProps, "slides"> & {
    className?: string;
    products: ProductItemRec[];
};

const SectionProducts: React.FC<SectionProductsProps> = ({
    className,
    title,
    link,
    headerContent,
    sliderProps,
    products,
}) => {
    if (_.isEmpty(products)) {
        return null;
    }

    return (
        <div className={cns(className, styles.component)}>
            <SectionSlider
                title={title}
                link={link}
                headerContent={headerContent}
                slides={products.map((card, cardIndex) => (
                    <CardProduct
                        key={cardIndex}
                        {...card}
                        mobileSlider={false}
                    />
                ))}
                sliderProps={sliderProps}
            />
        </div>
    );
};

export default SectionProducts;
